function debounce(fn, duration) {
  var timer;
  return function() {
    clearTimeout(timer);
    timer = setTimeout(fn, duration)
  }
}

document.addEventListener('turbolinks:load', () => {

  var cards = document.querySelectorAll('.dashboard-card');
  Array.from(cards).forEach(card => {
    if (card.dataset.url != null) {
      card.addEventListener('click', function(event) {
        window.location.assign(this.dataset.url);
      });
    }
  });

  communitySearchNameInput = document.querySelector('#community-search-name');
  if (communitySearchNameInput) {

    communitySearchNameInput.addEventListener('keyup', debounce(() => {
      param = communitySearchNameInput.value;
      url = communitySearchNameInput.dataset.url;
      url = `${url}?name=${param}`;
      Rails.ajax({
        url: url,
        type: "get"
      });
    }, 500));
  }
});
