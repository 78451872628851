document.addEventListener('turbolinks:load', () => {

  communitySelect = document.querySelector('#demand_community_id');
  internalAttendance = document.querySelector('#demand_attendance_internal');
  externalAttendance = document.querySelector('#demand_attendance_external');
  demandRequesterSelect = document.querySelector('#demand_requester_id');

  enableDemandFieldsHandler = function() {
    if (communitySelect.value == "") {
      internalAttendance.disabled = true;
      externalAttendance.disabled = true;
      demandRequesterSelect.disabled = true;
    } else {
      internalAttendance.disabled = false;
      externalAttendance.disabled = false;
      demandRequesterSelect.disabled = false;
    }
  }

  if (communitySelect && internalAttendance && externalAttendance && demandRequesterSelect) {

    enableDemandFieldsHandler();

    communitySelect.addEventListener('change', function() {
      enableDemandFieldsHandler();
      internalAttendance.checked = true;
      attendanceChangeEvent('internal');
    });

    attendanceChangeEvent = function (type) {
      communityId = communitySelect.value;
      url = `/requesters.js?type=${type}&community_id=${communityId}`;
      Rails.ajax({
        url: url,
        type: "get"
      });
    }

    externalAttendance.addEventListener('change', function() {
      attendanceChangeEvent('external');
    });
    internalAttendance.addEventListener('change', function() {
      attendanceChangeEvent('internal');
    });

  }


});
