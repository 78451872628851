// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

global.Rails = Rails;

import 'bootstrap'
import '@fortawesome/fontawesome-free/js/all'
import Inputmask from 'inputmask'
import flatpickr from 'flatpickr';
import { Portuguese } from "flatpickr/dist/l10n/pt.js"

import '../javascripts/home';
import '../javascripts/demands';

document.addEventListener("turbolinks:load", () => {
  flatpickr('.date', {
    'locale': Portuguese,
    'altInput': true,
    'altFormat': 'd/m/Y',
    'wrap': true
  });
  Inputmask("999.999.999-99", { jitMasking: true }).mask(document.querySelectorAll(".cpf"));
  Inputmask(["a{5}", "999.999.999-99"], { keepStatic: true, jitMasking: true }).mask(document.querySelectorAll(".username"));
  // Inputmask("99/99/9999", { jitMasking: true }).mask(document.querySelectorAll(".date"));
  Inputmask(["(99) 9999-9999", "(99) 99999-9999",], { keepStatic: true, jitMasking: true }).mask(document.querySelectorAll(".phone-number"));
  Inputmask("9999.99.999.99999-9", { jitMasking: true }).mask(document.querySelectorAll(".protocol-number"));
  Inputmask('decimal', {
    numericInput: true,
    groupSeparator: '.',
    radixPoint: ',',
    digits: 2,
    digitsOptional: false,
    prefix: '',
    placeholder: ''
  }).mask(document.querySelectorAll(".decimal"));

});
